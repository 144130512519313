<template>
  <div class="container my-24 my-lg-40">
    <div class="row">
      <div class="col">
        <v-sheet
          elevation="4"
          rounded="lg"
        >
          <div class="p-24 pb-40">
            <div class="row">
              <header class="col">
                <h2>Ваш список тестов</h2>
                <span class="text-color-text-secondary text-size-14">
                  Сейчас таймер отключен, можете отдохнуть
                </span>
              </header>

              <div class="col-auto">
                <v-button
                  link
                  @click="instruction.isShow = !instruction.isShow"
                  title="Читать инструкцию к тесту"
                >
                  Инструкция
                </v-button>
              </div>
            </div>
          </div>

          <v-table
            :rounded="false"
            list
            unbordered
          >
            <v-table-tr
              v-for="(test, index) in tests"
              :key="test.title"
              :style="{
                height: '3.5rem',
              }"
              :class="{
                'bg-color-background-4': test.status === 'closed',
              }"
            >
              <v-table-td class="pl-24">
                <span
                  :style="{opacity: test.status === 'closed' ? '.5' : null,}"
                  class="h4 text-color-text-secondary"
                >
                  {{ index + 1 }}
                </span>
              </v-table-td>

              <v-table-td class="w-100">
                <span
                  :style="{opacity: test.status === 'closed' ? '.5' : null,}"
                  class="h4"
                >
                  {{ test.title }}
                </span>
              </v-table-td>

              <v-table-td class="text-nowrap">
                <div :style="{opacity: test.status === 'closed' ? '.5' : null,}">
                  <v-icon-svg
                    class="svg-stroke-primary"
                    title="svg-icon-clock"
                    view-box="0 0 21 24"
                    width="1rem"
                    height="1rem"
                  >
                    <svg-icon-clock></svg-icon-clock>
                  </v-icon-svg>

                  {{ test.time }}
                </div>
              </v-table-td>

              <v-table-td class="text-right pr-24">
                <div
                  v-if="test.status === 'done'"
                  title="Пройдено"
                  class="text-nowrap"
                >
                  <v-icon-svg
                    class="mr-8 svg-stroke-success"
                    view-box="0 0 11 8"
                    width="16"
                    height="16"
                  >
                    <svg-icon-checked/>
                  </v-icon-svg>
                </div>

                <template v-if="test.status === 'open'">
                  <v-button
                    title="Начать выполнение задания"
                    outlined
                  >
                    Начать
                  </v-button>
                </template>
              </v-table-td>
            </v-table-tr>
          </v-table>
        </v-sheet>
      </div>
    </div>

    <v-modal
      v-model="instruction.isShow"
    >
      <template #header>
        <h2>Инструкция</h2>
      </template>

      <template #footer>
        <v-button @click="instruction.isShow = !instruction.isShow">
          Закрыть
        </v-button>
      </template>

      <h4>Добрый день!</h4>

      <p>
        Вы приглашены к прохождению теста управленческого потенциала.
        Данный тест измеряет качества, прогнозирующие успех человека
        в новых рабочих условиях и обстоятельствах.
      </p>

      <hr class="my-24">

      <p>
        <strong>
          Пожалуйста, учитывайте следующие особенности теста,
          связанные с ограничением времени:
        </strong>
      </p>

      <ul class="ul ul_type_lines">
        <li>
          <strong>Между разделами можно делать паузы.</strong>
        </li>
        <li>
          <strong>Внутри большинства разделов паузы делать нельзя</strong> — время на их прохождение ограничено. Если Вы
          закроете
          тест во время прохождения одного из блоков, таймер продолжет идти.
        </li>
        <li>
          <strong>Таймер расположен на виджете с правой стороны</strong>, рядом с тестовой частью.
        </li>
        <li>
          <strong>Количество минут на прохождение теста указывается
            в дополнительных инструкциях в каждом разделе.</strong>
        </li>
        <li>
          <strong>Общее время прохождения теста — 120 минут.</strong>
        </li>
        <li>
          <strong>Приготовьте заранее ручку / карандаш, бумагу, калькулятор.</strong>
          В одном разделе теста необходимо производить математические вычисления.
        </li>
        <li>
          <strong>Ответ засчитывается только при нажатии на кнопку «Ответить».</strong>
          Таким образом, Вы подтверждаете свой ответ.
        </li>
      </ul>

      <hr class="my-24">

      <p>
        Если у Вас возникнут вопросы или затруднения в ходе работы с системой
        — напишите в службу поддержки в вашем личном кабинете.
      </p>

      <p class="mb-0 mt-24">
        <strong>Желаем Вам удачи!</strong>
      </p>
    </v-modal>
  </div>
</template>

<script>
import {
  VSheet,
  VButton,
  VIconSvg,
  VModal
} from '@components/base'
import SvgIconClock from '@components/icons/SvgIconClock'
import SvgIconChecked from '@components/icons/SvgIconChecked'
import VTable from '@components/base/VTable/VTable'
import VTableTr from '@components/base/VTable/VTableTr'
import VTableTd from '@components/base/VTable/VTableTd'

export default {
  name: 'SSections',

  components: {
    VTableTd,
    VTableTr,
    VTable,
    VSheet,
    SvgIconChecked,
    SvgIconClock,
    VButton,
    VIconSvg,
    VModal
  },

  data () {
    return {
      instruction: {
        isShow: false
      },

      tests: [
        {
          title: 'Тренировочные задания',
          time: '20 мин',
          status: 'done'
        },
        {
          title: 'Обработка информации',
          time: '25 мин',
          status: 'done'
        },
        {
          title: 'Анализ идеи',
          time: '35 мин',
          status: 'open'
        },
        {
          title: 'Принятие решений',
          time: '20 мин',
          status: 'closed'
        },
        {
          title: 'Кейсы',
          time: '20 мин',
          status: 'closed'
        },
        {
          title: 'Мнения и отношения',
          time: '20 мин',
          status: 'closed'
        },
        {
          title: 'Лидерский стиль',
          time: '5 мин',
          status: 'closed'
        }
      ]
    }
  }
}
</script>
